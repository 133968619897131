// extracted by mini-css-extract-plugin
export var intro = "about-module--intro--xBOlf";
export var about_img_container = "about-module--about_img_container--2U7U-";
export var img = "about-module--img--3h34_";
export var about_hello = "about-module--about_hello--2qs8Q";
export var content = "about-module--content--34UM3";
export var about_img = "about-module--about_img--1HTCp";
export var heading = "about-module--heading--16aFe";
export var sub_heading = "about-module--sub_heading--bvWgT";
export var skills = "about-module--skills--1-zIR";
export var skills_icon = "about-module--skills_icon--IikyQ";
export var bounce = "about-module--bounce--Z69Tq";
export var bounceAnimation = "about-module--bounce-animation--39FZk";
export var work = "about-module--work--1JOyc";
export var work_container = "about-module--work_container--30RA7";
export var span = "about-module--span--1-cPk";
export var ping = "about-module--ping--39VO2";
export var notping = "about-module--notping--1HCAq";